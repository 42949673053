import React from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import TermsOfService from './components/TermsOfService/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import LandingPage from './components/LandingPage/LandingPage';
import API from './components/API'

function App(): JSX.Element {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route path='/' index element={<LandingPage />} />
          <Route path='/terms-of-service' element={<TermsOfService />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/api' element={<API />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
