import React from 'react';

function TermsOfService(): JSX.Element {
  return (
    <div className='tos-container'>
      <h1>Terms of Service</h1>

      <h2>Conditions of Use</h2>
      <p>
        We will provide their services to you, which are subject to the conditions stated below in this document.
        By using or accessing the otakugifs.xyz(Website), api.otakugifs.xyz(Api) and cdn.otakugifs.xyz(CDN) you accept the following conditions.
      </p>

      <h2>Copyright</h2>
      <p>
        Content, data and source code published on this website(otakugifs.xyz) or provided as a service from "api.otakugifs.xyz" or "cdn.otakugifs.xyz"
        (digital downloads, images, texts, graphics, logos) is property of OtakuGIFs
        and/or its content creators and protected by international copyright laws.
      </p>

      <h2>Warranty</h2>
      <p>
        Service is provided "AS IS", without warranties of any kind. While we will provide good user experience, we can not warrant that
        that the Service will be secure, error-free, without delays, downtimes and imperfections.
      </p>

      <h2>Third Party Content</h2>
      <p>
        We use third-party services to help us provide the Service, but such use does not indicate that we endorse them or are responsible or liable for their actions.
        Please note that your use of such third-party services will be governed by the terms and privacy policy applicable to the corresponding third party.
      </p>

      <h2>Feedback</h2>
      <p>
        We want to hear from you, either your complaints, suggestions or praise. Contact us at: otakugifs@gmail.com.
      </p>

      <h2>GIFs and Media</h2>
      <p>
        GIFs and any other media format provided by the Service is subject to change name, address, size and content or be completely removed.
        You are discouraged from saving or indexing any of the media files provided to you.
      </p>

      <p>
        OtakuGIFs reserves the right to update these Terms at will.
        OtakuGIFs reserves all rights to edit or remove content in their sole discretion, without prior notice, for any reason.
        OtakuGIFs is under no obligation to compensate you for any such loss.
      </p>
    </div>
  );
}

export default TermsOfService;
