import React from 'react';
import { landingPageAboutProps } from '../../../interfaces/interfaces';

function About(props: landingPageAboutProps): JSX.Element {
  const { aboutRef } = props.refs;

  return (
    <section className='landing-page-about' aria-labelledby='about' ref={aboutRef}>
      <h2 id='about'>About</h2>
      <p>
        OtakuGIFs aims to delivers high quality anime GIFs, WebPs & AVIFs as reactions,
        perfect for chat apps and Discord bots.
      </p>
      <p>Free to use and made with care!</p>
    </section>
  )
}

export default About;
