import React from 'react';
import arrowDownBlack from "../../../assets/arrow-down-blak.svg";
import { landingPageMainProps } from "../../../interfaces/interfaces";

function Main(props: landingPageMainProps): JSX.Element {

  const { aboutRef } = props.refs;

  return (
    <main className='landing-page-main'>
      <p>The best anime GIF collection,<br />in a free API.</p>
      <img src={arrowDownBlack} alt='arrow scroll down' onClick={() => aboutRef?.current?.scrollIntoView()} />
    </main>
  )
}

export default Main;
