import React, { useState } from 'react';

function CookiePopup(): JSX.Element {
  if (!localStorage.getItem('OGAcceptedCookie')) localStorage.setItem('OGAcceptedCookie', '');
  const [popupVisible, setPopupVisible] = useState(localStorage.getItem('OGAcceptedCookie') === '');

  const dismissPopup = () => {
    localStorage.setItem('OGAcceptedCookie', 'yes');
    setPopupVisible(false);
  };

  return (
    <section className={`cookie-popup ${popupVisible ? '' : 'hidden'}`}>
      <p>OtakuGIFs doesn't use cookies!</p>
      <button onClick={dismissPopup}>Dismiss
      </button>
    </section>
  )
}

export default CookiePopup;
