import React from 'react';
import { Link } from 'react-router-dom';

function Footer(props: any) {
  return (
    <>
      <footer style={props.styles}>
        <div className='footer-area'>
          <h4>Useful links</h4>
          <button className='display-block' onClick={() => window.open('https://discord.com/invite/3TQgpKK')}>Discord</button>
          <Link to='/privacy-policy' target="_blank">Privacy Policy</Link>
          <Link to='/terms-of-service' target="_blank">Terms of Service</Link>
          <p>Email: <u><b>otakugifs@gmail.com</b></u></p>
        </div>
        <div className='footer-area'>
          <h4>Support us</h4>
          <p>Donations: <a href="https://ko-fi.com/skillers3">Ko-Fi</a>. </p>
          <p>Looking for a hosting service? Use our affiliate links:
            &nbsp;
            <button onClick={() => window.open('https://hetzner.cloud/?ref=0fswF9Kv99Av')}>Hetzner</button>
            &nbsp;or&nbsp;
            <button onClick={() => window.open('https://m.do.co/c/f51cd516e684')}>DigitalOcean</button>
            &nbsp;
            and get free credits.
          </p>
        </div>
        <div className='footer-area'>
          <h4>Get involved</h4>
          <p>Visit our community on Discord and get involved.</p>
        </div>
      </footer>
      <div className='footer-copyright'>
        ©OtakuGIFs {new Date().getFullYear()}. All rights reserved.
      </div>
    </>
  )
}

export default Footer;
