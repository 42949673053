import React from 'react';
import nekosAPILogoDark from '../../../assets/nekos-api-logo.svg';
import newWindow from '../../../assets/new-window.png';
import { landingPageAdsProps } from '../../../interfaces/interfaces';

function Ads(props: landingPageAdsProps): JSX.Element {
    const { partnersRef } = props.refs;

    return (
        <section className='landing-page-partners' aria-labelledby='partners' ref={partnersRef}>
            <h2 id='partners'>Partners</h2>

            <section className='partner-container' aria-labelledby='partner-name'>
                <img src={nekosAPILogoDark} alt="nekos api logo with a cat" />
                <div>
                    <h3 id='partner-name'>Nekos API</h3>
                    <p>The open-source free Rest/GraphQL API serving thousands of anime images.</p>
                    <button onClick={() => window.open('https://nekosapi.com/')}>
                        Go to website <img src={newWindow} alt="open in new tab" />
                    </button>
                </div>
            </section>
        </section>
    )
}

export default Ads;