import React from 'react';
import { Link } from 'react-router-dom';
import { landingPageHeaderProps } from '../../../interfaces/interfaces';

function Header(props: landingPageHeaderProps): JSX.Element {
  const { aboutRef, featuresRef, faqRef, partnersRef } = props.refs;

  return (
    <header className='landing-page'>
      <h1>OtakuGIFs</h1>
      <nav>
        <button onClick={() => aboutRef?.current?.scrollIntoView()}>About</button>
        <button onClick={() => featuresRef?.current?.scrollIntoView()}>Features</button>
        <button onClick={() => faqRef?.current?.scrollIntoView()}>Faq</button>
        <button onClick={() => partnersRef?.current?.scrollIntoView()}>Partners</button>
        <Link to="/api" target="_blank">API</Link>
      </nav>
    </header>
  )
}

export default Header;
 
