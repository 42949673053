import React, { Fragment } from 'react';
import Footer from './Reuseable/Footer/Footer';
import ScrollToTop from './Reuseable/Buttons/ScrollToTop';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { monoBlue } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function Docs(): JSX.Element {
	return (
		<>
			<div className='api-ad'>
				<p>Enjoying OtakuGIFs? Consider donating to our <a href="https://ko-fi.com/skillers3"><u>Ko-Fi</u></a>!</p>
			</div>
			<main className='docs'>
				{data.map(data => <Fragment key={data.title}>
					<section className='info'>
						<h1>{data.title}</h1>
						<div className='line1'>
							<div className='method'>{data.method}</div>
							<p><u>{data.url}</u></p>
							<button onClick={() => navigator.clipboard.writeText(data.clipboard)}>Copy</button>
						</div>
						<p>{data.description}</p>
						<div className='request'>
							{data.pathParams}
							{data.queryParams}
						</div>
					</section>
					<section className='examples'>
						<SyntaxHighlighter className='container' showLineNumbers language="javascript" style={monoBlue}>
							{data.syntax}
						</SyntaxHighlighter>
					</section>
				</Fragment>)}
			</main>
			<div className='api-ad'>
				<p>Want your ad here? Contact us on <a target='_blank' href="https://discord.com/invite/3TQgpKK"><u>Discord</u></a></p>
			</div>
			<ScrollToTop />
			<Footer styles={{ marginTop: 0 }} />
		</>
	)
}

export default Docs;

const data = [
	{
		title: 'Fetch Image',
		method: 'GET',
		url: 'https://api.otakugifs.xyz/gif?reaction=kiss',
		clipboard: 'https://api.otakugifs.xyz/gif?reaction=kiss',
		description: <span>Returns a random image file based on the query parameter <i>reaction</i> in the format of the query parameter <i>format</i>.</span>,
		extraHeaders: <div className='headers'>
			<p className='header'>format</p>
			<p className='type'>GIF, AVIF, WEBP</p>
			<p className='desc'>Choose the return format. Defaults to GIF</p>
		</div>,
		pathParams: null,
		queryParams: <><h4>Query Parameters</h4>
			<div className='path-params'>
				<p className='param required'>reaction</p>
				<p className='type'>string</p>
				<p className='desc'>A valid reaction (kiss, hug, etc.)</p>
			</div>
			<div className='path-params'>
				<p className='param'>format</p>
				<p className='type'>string</p>
				<p className='desc'>A valid format (GIF, WebP or AVIF). Defaults to GIF.</p>
			</div>
		</>,
		syntax: `const result = await axios
	.get('https://api.otakugifs.xyz/gif?reaction=kiss&format=gif');

console.log(result.data);
{ 
   url: 'https://cdn.otakugifs.xyz/gifs/kiss/d7e51440.gif'
} `
	}, {
		title: 'Fetch All Reactions',
		method: 'GET',
		url: 'https://api.otakugifs.xyz/gif/allreactions',
		clipboard: 'https://api.otakugifs.xyz/gif/allreactions',
		description: 'Returns all available reactions.',
		pathParams: null,
		queryParams: null,
		syntax: `const result = await axios
	.get('https://api.otakugifs.xyz/gif/allreactions');

console.log(result.data);
{ 
    reactions: ['kiss', 'hug', 'bite', 'stare', 'pout', ...]
}`,
	},
];
