import React, { Component } from 'react';
import upArrow from '../../../assets/arrow-up-black.svg';

class ScrollToTop extends Component {
  state = {
    visible: false
  }

  toggleVisibility() {
    window.pageYOffset > 300
      ? this.setState({visible: true})
      : this.setState({visible: false});
  }

  componentDidMount() {
    let scrollComponent = this;
    document.addEventListener("scroll", () => {
      scrollComponent.toggleVisibility();
    });
  }

  componentWillUnmount() {
    let scrollComponent = this;
    document.removeEventListener("scroll", () => {
      scrollComponent.toggleVisibility();
    });
  }

  scrollToTop() {
    // @ts-ignore
    document.getElementById('scroll-top-button').blur();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  render() {
    return (
      <button
        id='scroll-top-button'
        className={`scroll-top-btn ${this.state.visible ? 'visible' : ''}`}
        onClick={this.scrollToTop}>
        <img src={upArrow} alt='arrow scroll to top'/>
      </button>
    );
  }
}

export default ScrollToTop;
